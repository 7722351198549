<template>
  <form v-if="v" class="space-y-4">
    <ValidatedInputGroup v-slot="{ isValid }" :errors="v.email.$errors">
      <FimInput
        id="username"
        v-model:value="editableUser.email"
        type="email"
        :debounce="0"
        :placeholder="$t('form_fields.email')"
        autocomplete="username"
        :is-valid="isValid"
        :disabled="isSubmitting"
        required
        data-testid="login-email"
        @update:value="v.email.$touch()"
      />
    </ValidatedInputGroup>

    <ValidatedInputGroup v-slot="{ isValid }" :errors="v.password.$errors">
      <FimInput
        id="password"
        v-model:value="editableUser.password"
        type="password"
        :debounce="0"
        :placeholder="$t('form_fields.password')"
        autocomplete="current-password"
        :is-valid="isValid"
        required
        :disabled="isSubmitting"
        data-testid="login-password"
        @update:value="v.password.$touch()"
      />
    </ValidatedInputGroup>

    <div class="text-right">
      <DefaultLink
        :to="{
          name: routeList.signin.name,
          query: { ['forgot-password']: 'true' },
        }"
        raw
        class="group relative text-sm text-gray-800 hover:text-black"
      >
        <span>{{ $t('login_page.forgot_password.title') }}</span>
        <span
          class="absolute inset-x-0 bottom-[-2px] h-px bg-gray-800 transition-all group-hover:inset-x-[-5px] group-hover:bg-black"
        />
      </DefaultLink>
    </div>

    <FimButton
      class="w-full"
      :disabled="isSubmitting"
      :loading="isSubmitting"
      data-testid="login-submit"
      @click="onSubmit()"
    >
      {{ $t('global.sign_in') }}
    </FimButton>
  </form>
</template>

<script setup lang="ts">
import useVuelidate from '@vuelidate/core'
const scope = effectScope()
onScopeDispose(() => scope.stop())
const { login, isSubmitting } = await useAuthentication('login')
const { lastLoggedInUser } = await useLastLoggedInUser()

const { $validation } = useNuxtApp()

const editableUser = reactive({
  email: '',
  password: '',
})

const v = useVuelidate(
  {
    email: {
      required: $validation.rule.required,
      email: $validation.rule.email,
    },
    password: {
      required: $validation.rule.required,
    },
  },
  editableUser,
)

const onSubmit = async () => {
  const isValid = await v.value.$validate()
  if (!isValid) {
    return
  }

  await login(editableUser)
}

scope.run(() => {
  if (import.meta.server) {
    return
  }
  watch(lastLoggedInUser, (user) => {
    editableUser.email = user.email
  })
})
</script>
